.section--main {
  .section__row {
    @extend .section__row--base-padding;
  }

  .section__content {
    ul {
      list-style: disc;
      margin-left: 1em;
    }
  }
}
