.slider {
  width: 100%;

  &__wrapper {
    height: 100%;
  }

  &__button--prev,
  &__button--next {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 40px auto;
    background-position: center;
    transition: all 0.2s ease;

    &.swiper-button-disabled {
      opacity: 0;
      transform: translateX(-20px);
      visibility: hidden;
    }

    @include breakpoint('s') {
      width: 50px;
      height: 50px;
      background-size: 50px auto;
    }
  }

  &__button--prev {
    background-image: url('../images/icon-arrow-left.svg');
    left: 0;
  }

  &__button--next {
    background-image: url('../images/icon-arrow-right.svg');
    right: 0;
  }
}
