// Basics
* {
  outline: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 100%;
  font-size: 15px;
}

body {
  font-size: 1rem;
  line-height: 1;
  font-family: $typo-family-body;
  font-weight: 400;
  color: $color-gray-mid;
  padding-top: 70px;

  @include breakpoint('s') {
    padding-top: 90px;
  }

  &.menu-open {
    overflow: hidden;
  }
}

// Headlines
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $typo-family-headline;
  font-weight: 700;
  line-height: 125%;
  margin: 0 0 1em 0;
  color: $color-gray-dark;
}

h1 {
  font-size: $typo-size-xl;

  @include breakpoint('s') {
    font-size: $typo-size-xxl;
  }

  @include breakpoint('m') {
    font-size: $typo-size-xxxl;
  }
}

h2 {
  font-size: $typo-size-xl;

  @include breakpoint('m') {
    font-size: $typo-size-xxl;
  }
}

h3 {
  font-size: $typo-size-l;

  @include breakpoint('m') {
    font-size: $typo-size-xl;
  }
}

h4 {
  font-size: $typo-size-b;

  @include breakpoint('m') {
    font-size: $typo-size-m;
  }
}

p {
  line-height: 150%;
}

// Links
a {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// Images
img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

// Lists
ul,
ol {
  line-height: 125%;
  list-style: none;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

strong {
  font-weight: 600;
}

hr {
  height: 4px;
  background-color: $color-gray-light;
  border: 0;
  margin: 0;
}

#overlay {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba($color-primary, 0.8);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

.menu-open #overlay {
  visibility: visible;
  opacity: 0.7;
}
