.section {
  width: 100%;
  position: relative;

  &--dark {
    background-color: $color-gray-dark;
    color: white;

    .section__title,
    h2,
    h3 {
      color: white;
    }
  }

  &__row {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    overflow: hidden;

    @include breakpoint('s') {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include breakpoint('m') {
      padding-left: 60px;
      padding-right: 60px;
    }

    &--base-padding {
      padding-top: 60px;
      padding-bottom: 60px;

      @include breakpoint('m') {
        padding-top: 125px;
        padding-bottom: 125px;
      }
    }

    &--small-padding {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &--no-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &--wide {
      max-width: 1200px;
    }

    &--narrow {
      max-width: 850px;
    }

    &--text-left {
      .section__title,
      .section__teaser,
      .section__content {
        text-align: left;
      }

      .section__title {
        margin-left: 20px;
      }
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: -20px;

    @include breakpoint('m') {
      flex-direction: row;
    }
  }

  &__column {
    flex: 1;
    max-width: calc(100% - 40px);
    margin: 20px;
  }

  &__date {
    color: $color-gray-mid;
    margin-top: 0;
  }

  &__title {
    @extend h1;
    margin-left: 20px;
    margin-bottom: 1em;
    position: relative;

    span {
      position: relative;
    }

    span::before {
      content: '';
      width: 4px;
      height: calc(100% - 12px);
      background-color: $color-primary;
      position: absolute;
      left: -20px;
      top: 6px;
    }

    @include breakpoint('s') {
      text-align: center;
      margin-left: 0;
    }

    &--left {
      text-align: left;
    }
  }

  &__teaser {
    font-weight: 600;
    color: $color-primary;
    text-transform: uppercase;
    margin: 0 auto 2em auto;

    @include breakpoint('s') {
      text-align: center;
    }
  }

  &__content {
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }

    h4 {
      line-height: 150%;
    }

    @include breakpoint('s') {
      text-align: center;
    }

    ul {
      list-style: disc;
      margin-left: 20px;
      line-height: 150%;

      li {
        margin: 10px 0;
      }
    }
  }
}
