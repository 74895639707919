.section--hero {
  .section__row {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
  }

  .slider {
    height: 300px;
    color: white;
    min-height: 50vh;

    @include breakpoint('s') {
      height: 300px;
    }

    @include breakpoint('m') {
      height: 400px;
    }

    @include breakpoint('xl') {
      height: 500px;
    }

    &__item {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__content {
    }

    &__line {
      animation-name: slideFromLeftToRight;
      animation-duration: 1s;
      transform: translateX(-100px);
      opacity: 0;
      visibility: hidden;
      animation-fill-mode: forwards;

      @include breakpoint('xs') {
        font-size: $typo-size-xxl;
      }

      @include breakpoint('s') {
        font-size: 3rem;
      }

      span {
        background-color: $color-primary;
        padding: 7px 16px;
        color: white;
      }

      &1 {
      }

      &2 {
        animation-delay: 1s;
      }

      &3 {
        animation-delay: 2s;
      }
    }
  }

  // Default hero
  &--default {
    .slider {
      height: 300px;
    }
  }

  // Small hero
  &--small {
    .slider {
      height: 200px;
    }
  }
}
