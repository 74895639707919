// All form fields
input[type='text'],
input[type='password'],
input[type='email'],
input[type='date'],
textarea,
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: $typo-family-body;
  background-color: white;
  border: 1px solid $color-primary;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='date'],
textarea {
  &:focus {
    background-color: white;
    color: $color-primary;
    border-color: $color-primary;
  }
}

textarea {
  height: 100px;
}

select {
  background-image: url('../images/icons/icon-chevron-down-gray.svg');
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-size: 14px auto;
}

select::-ms-expand {
  display: none;
}
