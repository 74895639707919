.section--banner-apply {
  min-height: 50vh;
  display: flex;
  align-items: center;

  @include breakpoint('s') {
    background-image: url('../images/klammern-grau@2x.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 400px auto;
  }

  h3 {
    max-width: 550px;
    margin: 0 auto 2rem auto;

    @include breakpoint('m') {
      font-size: 35px;
      max-width: 780px;
    }
  }

  &--white {
    background-color: #eee;

    @include breakpoint('s') {
      background-image: url('../images/klammer-big-white@2x.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 820px auto;
      background-attachment: fixed;
    }

    h3 {
      max-width: 650px;
      margin: 0 auto 1.5em auto;
      line-height: 150%;

      @include breakpoint('m') {
        font-size: 35px;
      }
    }
  }
}
