.isAnimated {
  opacity: 0;
  transition: 1s;

  &.fromTop {
    transform: translateY(-50px);

    &.entered {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.fromLeft {
    transform: translateX(-50px);

    &.entered {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.fromRight {
    transform: translateX(50px);

    &.entered {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.fromBottom {
    transform: translateY(50px);

    &.entered {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
