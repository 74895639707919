.section--jobs {
  background-color: #f4f4f4;

  .jobs {
    &__item {
      border-top: 4px solid $color-gray-light;
      margin-top: 30px;
      padding-top: 30px;

      @include breakpoint('s') {
        margin-top: 60px;
        padding-top: 60px;
      }
    }

    &__headline {
      color: $color-secondary;
      font-size: $typo-size-l;
      max-width: 650px;
      line-height: 150%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin: -20px;

      @include breakpoint('m') {
        flex-direction: row;
      }
    }

    &__column {
      margin: 20px;
      flex: 1;

      h3 {
        font-size: $typo-size-m;
      }
    }

    ul {
      line-height: 150%;
      margin-left: 20px;
      list-style: disc;

      li {
        margin: 5px 0;
      }
    }
  }
}
