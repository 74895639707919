.section--boilerplate {
  .section__title,
  .section__content {
    text-align: left;
  }

  .section__title {
    margin-left: 20px;
  }

  .contact {
    display: flex;
    flex-direction: column;

    @include breakpoint('s') {
      flex-direction: row;
    }

    &__info {
      flex: 1;
    }

    &__map {
      &__overlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 24px 48px;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: #f5f5f5;
      }

      &__wrapper {
        position: relative;
        flex: 1;
        min-height: 300px;
        margin: 20px -20px -60px -20px;

        @include breakpoint('m') {
          flex: 2;
          margin: 0;
        }

        #map {
          width: 100%;
          min-height: 300px;
        }
      }
    }

    &__title {
      @extend %smallHeadlineWithDash;

      &--hh {
        margin-top: 2em;
      }
    }
  }
}
