.section--news {
  text-align: left;

  .section__title,
  .section__teaser,
  .section__content {
    text-align: left;
  }

  .section__teaser {
    line-height: 150%;
  }

  .section__title {
    @include breakpoint('m') {
      font-size: $typo-size-xxl;
    }
  }
}
