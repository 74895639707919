.button {
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  border: 0;
  font-family: $typo-family-body;
  line-height: 100%;
  padding: 12px 20px;
  font-weight: bold;
  cursor: pointer;

  &--primary {
    color: white;
    background-color: $color-primary;
    border-radius: 4px;

    &:hover {
      background-color: $color-gray-dark;
    }
  }

  &--big {
    @include breakpoint('s') {
      font-size: $typo-size-l;
      padding: 14px 28px;
    }
  }
}
