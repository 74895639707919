.navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: $typo-family-body;
  line-height: 100%;

  @include breakpoint('s') {
    flex-direction: row;
    justify-content: flex-end;
  }

  &__item {
    flex: 1;
    line-height: 100%;
    position: relative;
    margin-top: 10px;

    @include breakpoint('s') {
      margin-top: 0;
      flex: 0 1 auto;
    }

    &--active {
      a {
        color: $color-primary;
      }
    }

    &:hover .subnavigation {
      transform: translateY(2px);
      opacity: 1;
      visibility: visible;
    }
  }

  &__link {
    display: block;
    padding: 8px 20px;
    line-height: 100%;
    color: $color-gray-dark;
    font-weight: 600;
    transition: 0.2s;

    @include breakpoint('s') {
      text-align: center;
      padding: 12px;
    }

    &:hover {
      color: $color-gray-mid;
    }
  }

  .subnavigation {
    @include breakpoint('s') {
      width: 250px;
      position: absolute;
      top: 100%;
      left: 12px;
      opacity: 0;
      visibility: hidden;
      transition: 0.5s;
      transform: translateY(-20px);
      background-color: $color-gray-dark;

      &:after {
        bottom: 100%;
        left: 24px;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(56, 65, 70, 0);
        border-bottom-color: #384146;
        border-width: 8px;
        margin-left: -8px;
      }
    }

    &__item {
      padding-left: 20px;

      @include breakpoint('s') {
        border-top: 1px solid rgba($color-gray-mid, 0.5);
        padding-left: 0px;

        &:first-child {
          border-top: 0;
        }
      }
    }

    &__link {
      display: block;
      padding: 8px 20px;
      color: $color-gray-mid;
      transition: 0.2s;

      @include breakpoint('s') {
        padding: 8px;
      }

      &:hover {
        color: $color-primary;
      }
    }
  }
}

#hamburger {
  position: absolute;
  top: 24px;
  right: 20px;
  width: 24px;
  height: 24px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 13;

  @include breakpoint('s') {
    display: none;
  }

  span {
    width: 100%;
    display: block;
    position: absolute;
    height: 3px;
    background-color: $color-primary;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    opacity: 1;

    &:nth-child(1) {
      top: 4px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 10px;
    }
    &:nth-child(4) {
      top: 16px;
    }
  }
}

.menu-open #hamburger {
  span {
    &:nth-child(1),
    &:nth-child(4) {
      top: 10px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}
