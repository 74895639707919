.section--portfolio {
  min-height: 50vh;
  display: flex;
  align-items: center;

  @include breakpoint('s') {
    background-image: url('../images/klammern-grau@2x.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 400px auto;
  }

  .portfolio {
    display: flex;
    flex-direction: column;
    margin: -10px;

    @include breakpoint('m') {
      flex-direction: row;
    }

    &__item {
      margin: 10px;
      background-color: #454c50;
      flex: 1;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      cursor: pointer;
      position: relative;

      > * {
        flex: 0 1 auto;
        max-width: 100%;
      }

      @include breakpoint('m') {
        min-height: 300px;
      }
    }

    &__headline {
      color: white;
      font-size: $typo-size-l;
      margin-bottom: 0.5em;

      @include breakpoint('s') {
        font-size: $typo-size-l;
      }
    }

    &__content {
      p {
        padding: 0;
        margin: 0;
      }
    }

    &__readmore {
      flex: 0 0 auto;
      margin-top: 20px;
    }

    &__overlink {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .portfolio__item:hover {
    background-color: $color-primary;
    transform: scale(1.15);
    z-index: 1;

    .button {
      background-color: $color-gray-dark;
    }
  }
}
