.news {
  &__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    margin-bottom: 60px;
    border-bottom: 4px solid $color-gray-light;

    @include breakpoint('s') {
      flex-direction: row;
    }

    @include breakpoint('m') {
      padding-bottom: 125px;
      margin-bottom: 125px;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &__content {
    flex: 1;

    @include breakpoint('s') {
      margin: 0 15px 0 0;
    }
  }

  &__title {
    @extend %smallHeadlineWithDash;
  }

  &__teaser {
  }

  &__readmore {
  }

  &__image {
    flex: 1;
    order: -1;
    margin: 0 0 30px 0;

    @include breakpoint('s') {
      order: 0;
      margin: 0 0 0 15px;
    }
  }
}
