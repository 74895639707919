.section--banner {
  background-color: $color-primary;
  color: white;
  min-height: 50vh;
  display: flex;
  align-items: center;

  @include breakpoint('s') {
    background-image: url('../images/klammer-big-white@2x.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 820px auto;
    background-attachment: fixed;
  }

  .banner {
    &__text {
      color: white;
      font-weight: 400;

      strong {
        font-weight: 700;
      }

      @include breakpoint('m') {
        font-size: 55px;
      }
    }

    &__line {
      display: block;
    }
  }
}
