.section--workflow {
  min-height: 50vh;
  min-height: 50vh;
  display: flex;
  align-items: center;

  .workflow-chart {
    display: flex;
    flex-direction: column;
    margin: -20px;
    position: relative;

    @include breakpoint('s') {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include breakpoint('m') {
      min-height: 540px;
    }

    &__item {
      list-style: none;
      margin: 20px;
      display: flex;
      flex-direction: column;

      @include breakpoint('s') {
        flex: 0 0 calc(50% - 40px);
        flex-basis: calc(50% - 40px);
      }

      &--diagramm {
        @include breakpoint('s') {
          flex: 0 0 100%;
        }

        @include breakpoint('m') {
          position: absolute;
          left: calc(50% - 245px);
        }
      }

      @include breakpoint('m') {
        &:nth-child(3),
        &:nth-child(5) {
          align-items: flex-end;
        }
      }
    }

    &__container {
      display: flex;
      text-align: left;

      @include breakpoint('m') {
        max-width: 50%;
      }
    }

    &__diagramm {
      width: 100%;

      img {
        max-width: 450px;
        height: auto;
        margin: 0 auto;
      }
    }

    &__icon {
      flex: 0 0 25px;
      width: 25px;
    }

    &__content {
      flex: 1;
      margin-left: 20px;
    }

    &__headline {
      @extend %smallHeadlineWithDash;
    }

    &__desc {
    }
  }
}
