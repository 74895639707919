#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  height: 70px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background-color: white;

  @include breakpoint('s') {
    height: 90px;
  }

  .section__row {
    height: 100%;
    display: flex;
    overflow: visible;
  }

  .section__columns {
    margin: 0;
    height: 100%;

    @include breakpoint('s') {
      flex-direction: column;
      justify-content: space-between;
      flex: 0 1 auto;
    }
  }

  .nav {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    transition: 0.5s;
    transform: translateY(-100%);

    @include breakpoint('s') {
      opacity: 1;
      visibility: visible;
      position: relative;
      align-items: center;
      transform: translateY(0);
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }
  }

  .logo {
    max-width: 180px;
    height: auto;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;

    @include breakpoint('s') {
      max-width: 230px;
    }

    &__link {
      display: block;

      img {
        width: 100%;
      }
    }
  }
}

.menu-open #header {
  .nav {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
