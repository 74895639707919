#cookie-banner {
  background-color: white;
  min-width: 260px;
  max-width: 600px;
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  display: none;

  @include breakpoint('m') {
    padding: 40px;
  }

  h2 {
    margin-top: 0;
  }

  p {
    line-height: 150%;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint('m') {
      flex-direction: row;
    }

    .link {
      flex: 1;
      font-family: $typo-family-body;
    }

    .button {
      flex: 0 1 auto;
      margin: 10px 0 0 0;
      background-color: $color-primary;
      color: white;

      @include breakpoint('m') {
        margin: 0 0 0 20px;
      }
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  .more {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
#cookie-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-primary, 0.6);
  z-index: 99;
  display: none;
  cursor: pointer;
}
