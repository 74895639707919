%boxShadowDefault {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

%overlay {
  background-color: rgba($color-primary, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 1;
}

%card {
  padding: 20px;
  background-color: white;
  @extend %boxShadowDefault;

  @include breakpoint('s') {
    padding: 30px;
  }
}

%smallHeadlineWithDash {
  font-weight: 700;
  color: $color-gray-dark;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
  font-size: $typo-size-b;
  text-align: left;
  line-height: 150%;

  &::after {
    content: '';
    height: 2px;
    background-color: $color-primary;
    bottom: -12px;
    left: 0;
    width: 20px;
    position: absolute;
  }
}
