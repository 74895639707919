.section--referenzen {
  background-color: $color-gray-dark;
  color: white;
  min-height: 50vh;
  display: flex;
  align-items: center;

  @include breakpoint('s') {
    background-image: url('../images/klammern-grau@2x.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 400px auto;
  }

  .section__title {
    color: white;
  }

  .references-slider {
    margin: 0 -20px;

    .references {
      height: auto;
      margin-left: 0;

      &__item {
        height: auto;
        display: flex;
        flex-direction: column;

        @include breakpoint('s') {
          justify-content: center;
        }
      }

      &__headline,
      &__content {
        padding: 0 40px;

        @include breakpoint('s') {
          padding: 0 60px;
        }
      }

      &__headline {
        color: white;
        line-height: 150%;
        font-size: $typo-size-m;

        @include breakpoint('m') {
          font-size: $typo-size-l;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 20px;
      height: 37px;
      margin-top: -17px;
      background-size: 20px auto;
    }

    .swiper-button-prev {
      background-image: url('../images/icons/chevron-left.svg');
    }
    .swiper-button-next {
      background-image: url('../images/icons/chevron-right.svg');
    }
  }
}
