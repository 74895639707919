.section--services {
  background-color: $color-gray-dark;
  color: white;
  min-height: 50vh;
  display: flex;
  align-items: center;

  @include breakpoint('s') {
    background-image: url('../images/klammern-grau@2x.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 400px auto;
  }

  .services {
    display: flex;
    flex-direction: column;
    margin: -20px;

    @include breakpoint('m') {
      flex-direction: row;
    }

    &__item {
      margin: 20px;
      list-style: none;
      list-style-type: none;
    }

    &__icon {
      margin-bottom: 30px;
      transition: 0.2s;
    }

    &__headline {
      @extend %smallHeadlineWithDash;
      color: white;

      @include breakpoint('m') {
        text-align: center;

        &:after {
          left: calc(50% - 6px);
        }
      }
    }

    &__content {
      color: rgba(#ffffff, 0.5);
    }
  }

  .services__item:hover {
    .services__icon {
      transform: scale(1.2);
    }
  }
}
