.section--footer {
  color: white;
  background-color: $color-gray-dark;
  font-size: $typo-size-s;
  font-weight: 700;

  @include breakpoint('s') {
    text-align: left;
  }

  .section__content {
    text-align: left;
    width: 100%;
  }

  .section__columns {
    display: block;

    @include breakpoint('s') {
      display: flex;
    }
  }

  .section__column {
    display: flex;
    justify-content: center;
    align-items: center;

    &--logos {
      flex: 0 1 auto;
    }

    &--nav {
      flex: 1;
      display: flex;

      @include breakpoint('m') {
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: -10px;

    &__item {
      margin: 10px;
      list-style: none;
      list-style-type: none;

      &--dmm {
        max-width: 190px;
      }

      &--bvmw {
        max-width: 150px;
      }
    }
  }

  .nav {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    margin: -5px;

    @include breakpoint('s') {
      flex-direction: row;
      justify-content: flex-end;
    }

    &__item {
      margin: 5px;
      list-style: none;
      list-style-type: none;
    }

    &__link {
      color: white;
      transition: 0.2s;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $color-primary;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
      }

      &:hover {
        color: $color-primary;

        &::after {
          transform: translateY(5px);
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
