// BREAKPOINTS
// Settings: min or max
$media-direction: min;

// Settings: Breakpoints
$breakpoints: (
  'default': '',
  'xs': 480px,
  's': 667px,
  'm': 1025px,
  'l': 1281px,
  'xl': 1681px
);

// Mixin itself
@mixin breakpoint($breakpoint, $direction: $media-direction) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}

// CENTERER
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba($color-primary, 0.75);
}

@keyframes slideFromLeftToRight {
  from {
    transform: translateX(-50px);
    opacity: 0;
    visibility: hidden;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
