@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('/assets/fonts/open-sans-v34-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/open-sans-v34-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('/assets/fonts/open-sans-v34-latin-600.woff2') format('woff2'),
    url('/assets/fonts/open-sans-v34-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('/assets/fonts/open-sans-v34-latin-700.woff2') format('woff2'),
    url('/assets/fonts/open-sans-v34-latin-700.woff') format('woff');
}

// COLORS
$color-primary: #abc226;
$color-secondary: #104992;
$color-gray-light: #eeeeee;
$color-gray-mid: #989898;
$color-gray-dark: #384146;

$color-error: #b22222;
$color-success: #00cc00;

// TYPO
// Font familys
$typo-family-body: 'Open Sans', Arial, Helvetica, sans-serif;
$typo-family-headline: 'Open Sans', Times, Georgia, serif;

// Font sizes
$typo-size-s: 0.8125rem; // 13px
$typo-size-b: 0.9375rem; // 15px
$typo-size-m: 1rem; // 16px
$typo-size-l: 1.375rem; // 22px
$typo-size-xl: 1.75rem; // 28px
$typo-size-xxl: 2.125rem; // 34px
$typo-size-xxxl: 2.5rem; // 40px
