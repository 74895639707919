.section--locations {
  min-height: 50vh;
  display: flex;
  align-items: center;

  .locations {
    display: flex;
    flex-direction: column;
    margin: -20px;

    @include breakpoint('s') {
      flex-direction: row;
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin: 20px;
      flex: 1;

      &:hover {
        .locations__image img {
          transform: scale(1.3);
        }
      }
    }

    &__image {
      max-width: 560px;
      margin: 0 auto 20px auto;
      overflow: hidden;

      img {
        transition: 0.3s;
      }
    }

    &__headline {
      @extend %smallHeadlineWithDash;
      color: white;
      text-align: center;

      &::after {
        left: calc(50% - 6px);
      }
    }
  }
}
