@import url('//hello.myfonts.net/count/358632');

@font-face {
  font-family: 'Scala Bold';
  src: url('../fonts/358632_0_0.eot');
  src: url('../fonts/358632_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/358632_0_0.woff2') format('woff2'),
    url('../fonts/358632_0_0.woff') format('woff'), url('../fonts/358632_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Scala Regular';
  src: url('../fonts/358632_1_0.eot');
  src: url('../fonts/358632_1_0.eot?#iefix') format('embedded-opentype'), url('../fonts/358632_1_0.woff2') format('woff2'),
    url('../fonts/358632_1_0.woff') format('woff'), url('../fonts/358632_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Scala Web';
  src: url('../fonts/358632_2_0.eot');
  src: url('../fonts/358632_2_0.eot?#iefix') format('embedded-opentype'), url('../fonts/358632_2_0.woff2') format('woff2'),
    url('../fonts/358632_2_0.woff') format('woff'), url('../fonts/358632_2_0.ttf') format('truetype');
}
